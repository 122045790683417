<template>
  <el-row v-loading="loading">

    <HowToModal/>

    <el-dialog
      v-loading="loading"
      :visible="viewModal"
      :before-close="closeModal"
      :close-on-click-modal="false"
      width="80%"
      class="modal"
    >
      <router-view></router-view>
    </el-dialog>

    <div class="intro" v-if="!loading">
      <el-row>
        <el-col :lg="6" :md="6" :sm="12" :xs="24" class="content">
          <div class="logo-big">
            <MainLogo class="logo" />
          </div>
        </el-col>
        <el-col :lg="8" :md="10" :sm="12" :xs="24" class="content">
          <div class="copy" >
            <div class="padding">
              <h3>Spark your Creativity!</h3>
              <p>The Virtual Quilt advocates for the artist in us all by offering a free, ever-changing digital patchwork that is also a never ending art installation. At its core, the digital framework enables its visitors to enjoy an interactive gallery and leave their artistic legacy. As squares are continuously added, the Quilt’s digital stitches weave a one-of-a-kind mosaic of creativity and connection.</p><br/>

              <p class="underlined" @click="openHowToModal">Find out how it works</p>
              <Arrow class="arrow" @click="openFormModal"/>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>

      <el-row class="quilt" ref="quilt"> 

        <el-col :md="3" :sm="4" :xs="8"
          v-for="(asset,index) in assets"
          :key="index"
        >
          <div class="artwork" @click="$router.push({ path:`/quilt/${asset.id}` })">

            <div v-if="checkIfSafari">

              <el-image
                v-if="asset.filetype == 'image'" 
                class="artwork"
                :src="asset.image_blob"
              ></el-image>
              
              <el-image
                v-if="asset.filetype == 'video'" 
                class="artwork"
                :src="asset.video_thumb"
              ></el-image>

            </div>

            <div v-else>

              <el-image
                v-if="asset.filetype == 'image'" 
                class="artwork"
                :src="asset.image_blob"
                fit="cover"
                lazy
              ></el-image>
              
              <el-image
                v-if="asset.filetype == 'video'" 
                class="artwork"
                :src="asset.video_thumb"
                fit="cover"
                lazy
              ></el-image>

            </div>
            
          </div>
        </el-col>

      </el-row>

      <div v-if="loading" class="loadingIndicator">
        Loading... <i class="el-icon-loading"></i> 
      </div>

  </el-row>
</template>

<script>
  import { mapState, mapGetters, mapMutations, mapActions } from "vuex"
  import MainLogo from '@/assets/Logo.svg';
  import Arrow from '@/assets/Arrow.svg';
  import HowToModal from '@/components/HowToModal'

  export default {

    data() {
      return {
        page: 0
      }
    },

    components: {
      MainLogo,
      Arrow,
      HowToModal
    },

    mounted () {

      if(this.$route.name == 'startsharing') {
        this.openFormModal()
      }

      this.loadItems()

      const _self = this
      window.onscroll = function() {
        if ((window.innerHeight + window.pageYOffset) >= document.body.offsetHeight) {
          _self.loadItems()
        }
      };
    },

    updated () {
      if(this.$route.name == 'startsharing') {
        this.openFormModal()
      }
    },

    watch: {
      $route: {
        immediate: true,
        handler: function() {
          if(this.$route.params.assetId) {
            this.getAssetDetails(this.$route.params.assetId)
            this.openViewModal()
          } else {
            this.closeViewModal()
          }
        }
      }
    },

    computed: {
      ...mapState([
        'loading',
        'viewModal',
        'feed',
        'assets',
        'totalPages'
      ]),
      ...mapGetters([
        'shuffledAssets'
      ]),

      checkIfSafari: function() {
        var isSafari = 
          navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
          navigator.userAgent &&
          navigator.userAgent.indexOf('CriOS') == -1 &&
          navigator.userAgent.indexOf('FxiOS') == -1;

        return isSafari

      }
    },
    
    methods: {
      ...mapMutations([
        'openFormModal',
        'openViewModal',
        'closeViewModal',
        'openHowToModal',
        'closeHowToModal',
        'setAsset',
        'setAssets',
        'pushAsset',
        'setLoading'
      ]),
      ...mapActions([
        'fetchAssets',
        'getAssetDetails'
      ]),

      loadItems: function () {
                
        if(this.page < this.totalPages) {
          this.page++
        } else {
          this.page = 1
        }

        this.setLoading(true)

        this.fetchAssets(this.page)
        .then(() => {
          this.setAssets(this.feed)
          this.setLoading(false)
        });
        
      },

      closeModal: function() {
        this.$router.push({ path:`/quilt` })
        this.setAsset({})
        this.closeViewModal()
      }
    }
  };
</script>